import * as React from 'react'
import { useMemo } from 'react'
import {
  ToastEnvironment,
  getCurrentEnvironment
} from '@toasttab/current-environment'

type ActivationEnvironmentConfig = {
  activationEnv: ToastEnvironment
}

const ActivationEnvironmentContext =
  React.createContext<ActivationEnvironmentConfig>({
    activationEnv: getCurrentEnvironment()
  })

export const ActivationEnvironmentProvider = ({
  activationEnv,
  children
}: React.PropsWithChildren<{ activationEnv: ToastEnvironment }>) => {
  const activationEnvValue = useMemo(
    () => ({ activationEnv: activationEnv }),
    [activationEnv]
  )
  return (
    <ActivationEnvironmentContext.Provider value={activationEnvValue}>
      {children}
    </ActivationEnvironmentContext.Provider>
  )
}

export const useActivationEnvironmentContext = () =>
  React.useContext(ActivationEnvironmentContext)
