import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'

import { PageWrapper } from '../components/PageWrapper/PageWrapper'
import { ConfirmationPage } from '@local/user-activation/src/components'
import { ConfigurableErrorPage } from '@local/depot-error-pages'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackBarProvider>
        <div id='banquetPortalsContainer' />
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/user-verification' element={<PageWrapper />} />
            <Route
              path='/user-verification/confirmation-page'
              element={<ConfirmationPage />}
            />
            <Route
              path='*'
              element={<ConfigurableErrorPage errorType={'NOT_FOUND'} />}
            />
          </Routes>
        </BrowserRouter>
      </SnackBarProvider>
    </QueryClientProvider>
  )
}
