import { ToastEnvironment } from '@toasttab/current-environment'

const SANDBOX_BASE_URL = 'https://developer.sandbox.eng.toasttab.com'
const PROD_BASE_URL = 'https://developer.toasttab.com'

// For testing purposes
const PREPROD_BASE_URL = 'https://developer.preprod.eng.toasttab.com'
const DEV_BASE_URL = 'https://developer.dev.eng.toasttab.com'

const USERS_SERVICE_FOR_DEVELOPERS_BASE_URL = 'users/v1/developers'

export const ACTIVATE_USER_URL = (env: ToastEnvironment) => {
  switch (env) {
    case ToastEnvironment.DEV:
      return `${DEV_BASE_URL}/${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/activate`
    case ToastEnvironment.PREPROD:
      return `${PREPROD_BASE_URL}/${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/activate`
    case ToastEnvironment.SANDBOX:
      return `${SANDBOX_BASE_URL}/${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/activate`
    case ToastEnvironment.PROD:
      return `${PROD_BASE_URL}/${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/activate`
    default:
      // by default the current env/host is inferred for the base api url
      return `${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/activate`
  }
}

const USERS_SECURITY_KEY_URL = `${USERS_SERVICE_FOR_DEVELOPERS_BASE_URL}/security-key`
export const GET_SECURITY_KEY_DATA_URL = (
  securityKey: string,
  env: ToastEnvironment
) => {
  switch (env) {
    case ToastEnvironment.DEV:
      return `${DEV_BASE_URL}/${USERS_SECURITY_KEY_URL}/${securityKey}`
    case ToastEnvironment.PREPROD:
      return `${PREPROD_BASE_URL}/${USERS_SECURITY_KEY_URL}/${securityKey}`
    case ToastEnvironment.SANDBOX:
      return `${SANDBOX_BASE_URL}/${USERS_SECURITY_KEY_URL}/${securityKey}`
    case ToastEnvironment.PROD:
      return `${PROD_BASE_URL}/${USERS_SECURITY_KEY_URL}/${securityKey}`
    default:
      // by default the current env/host is inferred for the base api url
      return `${USERS_SECURITY_KEY_URL}/${securityKey}`
  }
}

export const getCorsOriginAllowed = (env: ToastEnvironment) => {
  switch (env) {
    case ToastEnvironment.DEV:
      return `https://developer.dev.eng.toasttab.com`
    case ToastEnvironment.PREPROD:
      return `https://developer.preprod.eng.toasttab.com`
    case ToastEnvironment.SANDBOX:
      return `https://developer.sandbox.eng.toasttab.com`
    case ToastEnvironment.PROD:
      return `https://developer.toasttab.com`
    default:
      return null
  }
}
