import axios, { AxiosPromise } from 'axios'
import { ToastEnvironment } from '@toasttab/current-environment'
import { GET_SECURITY_KEY_DATA_URL, getCorsOriginAllowed } from '../constants'
import { SecurityKeyData } from '@local/types'

export const getSecurityKeyData = (
  securityKey: string,
  environment: ToastEnvironment
): AxiosPromise<SecurityKeyData> => {
  const corsOriginValue = getCorsOriginAllowed(environment)
  const corsHeaders = corsOriginValue
    ? {
        'Access-Control-Allow-Origin': corsOriginValue,
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept'
      }
    : {}
  return axios.get(GET_SECURITY_KEY_DATA_URL(securityKey, environment), {
    headers: corsHeaders
  })
}
