export interface SecurityKeyData {
  userGuid: string
  userEmail: string
  userName: string
  organizationGuid: string | undefined
  organizationName: string | undefined
  type: DeveloperSecurityKeyType
}

export enum DeveloperSecurityKeyType {
  INVITATION = 'INVITATION',
  RESET_PASSWORD = 'RESET_PASSWORD'
}
