import { useQuery } from 'react-query'
import { ToastEnvironment } from '@toasttab/current-environment'
import { api } from '../api'
import { HttpStatusCode } from 'axios'
import { useState } from 'react'

export const useSecurityKeyData = (
  securityKey: string,
  environment: ToastEnvironment
) => {
  // the first environment to be checked will be the actual one (preprod or prod)
  // the second environment will be preprod, when working on it locally, or sandbox based on the current environment as a fallback.
  const env_1 = environment
  const env_2 =
    environment === ToastEnvironment.DEV ||
    environment === ToastEnvironment.PREPROD
      ? ToastEnvironment.PREPROD
      : ToastEnvironment.SANDBOX

  const [activationEnv, setActivationEnv] = useState(environment)
  const {
    isLoading: securityKeyDataLoading,
    error: securityKeyDataError,
    data
  } = useQuery(['getSecurityKey', securityKey], () =>
    api.getSecurityKeyData(securityKey, env_1).then(
      (response) => {
        return response
      },
      (reason) => {
        // if getting 429, we don't want them to try in a different environment in any case
        if (reason.response?.status === HttpStatusCode.TooManyRequests)
          return reason
        setActivationEnv(env_2)
        return api.getSecurityKeyData(securityKey, env_2)
      }
    )
  )

  return {
    securityKeyData: data?.data,
    securityKeyDataLoading,
    securityKeyDataError,
    activationEnv // this is the environment where the security key data were found
  }
}
