import * as React from 'react'
import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration,
  Error500DesktopIllustration,
  Error500MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

export interface ErrorDefinition {
  id: string
  heading: string
  subheading: string | React.JSX.Element
  additionalContent: string | React.JSX.Element | undefined
  imageDesktop: React.JSX.Element
  imageMobile: React.JSX.Element
}

const expiredLinkSubHeading = (
  <>
    <div className='type-large additionalContent-secondary'>
      Please contact your administrator. They can resend the invite email.
    </div>
    <div className='mt-2 type-large additionalContent-secondary'>
      Are you the administrator? Please contact{' '}
      <a
        href='https://developers.toasttab.com/hc/en-us/requests/new'
        className='inline-link'
      >
        Developer Support
      </a>{' '}
      for help.
    </div>
  </>
)

const securityErrorSubheading = (
  <>
    <div className='type-large additionalContent-secondary'>
      Please contact your administrator. They know where the spare key is
      hidden.
    </div>
    <div className='mt-2 type-large additionalContent-secondary'>
      Are you the administrator? Please contact{' '}
      <a
        href='https://developers.toasttab.com/hc/en-us/requests/new'
        className='inline-link'
      >
        Developer Support
      </a>{' '}
      for help.
    </div>
  </>
)

export const HANDLED_ERRORS = {
  NOT_FOUND: {
    id: 'custom-404-error-page',
    heading: 'Sorry! This dish is no longer on the menu.',
    subheading: 'We don’t have the ingredients to make this anymore.',
    additionalContent: '404: PAGE NOT FOUND',
    imageDesktop: <Permissions403DesktopIllustration />,
    imageMobile: <Permissions403MobileIllustration />
  },
  EXPIRED_LINK: {
    id: 'expired-link-error-page',
    heading: 'That link has expired.',
    subheading: expiredLinkSubHeading,
    additionalContent: undefined,
    imageDesktop: <Permissions403DesktopIllustration />,
    imageMobile: <Permissions403MobileIllustration />
  },
  SECURITY_ERROR: {
    id: 'security-error-page',
    heading: 'Someone needs to unlock this for you.',
    subheading: securityErrorSubheading,
    additionalContent: undefined,
    imageDesktop: <Permissions403DesktopIllustration />,
    imageMobile: <Permissions403MobileIllustration />
  },
  GENERIC_ERROR: {
    id: 'custom-generic-error-page',
    heading: 'Oops! We have some cleaning up to do.',
    subheading: (
      <div className='type-large additionalContent-secondary'>
        Please try reloading the page.
      </div>
    ),
    additionalContent: (
      <Button iconLeft={<AutorenewIcon accessibility='decorative' />}>
        Reload
      </Button>
    ),
    imageDesktop: <Error500DesktopIllustration />,
    imageMobile: <Error500MobileIllustration />
  }
} as const
