import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { ToastEnvironment } from '@toasttab/current-environment'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { api } from '../api'

export const useMutationActivateUser = (
  orgName: string,
  activationEnv: ToastEnvironment
) => {
  const navigate = useNavigate()
  const { showErrorSnackBar } = useSnackBar()

  const { isLoading: loading, mutate: activateUserMutation } = useMutation(
    'activate',
    (password: string) => api.activateUser(password, activationEnv),
    {
      onSuccess: () => {
        navigate('/user-verification/confirmation-page', {
          state: { orgName: orgName }
        })
      },
      onError: () => {
        showErrorSnackBar(
          'There was an issue activating the user, please refresh or try again later'
        )
      }
    }
  )

  return { activateUserMutation, loading }
}
