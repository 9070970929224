import axios, { AxiosPromise } from 'axios'
import { ToastEnvironment } from '@toasttab/current-environment'
import { ACTIVATE_USER_URL, getCorsOriginAllowed } from '../constants'

export const activateUser = (
  password: string,
  environment: ToastEnvironment
): AxiosPromise<string> => {
  const corsOriginValue = getCorsOriginAllowed(environment)
  const corsHeaders = corsOriginValue
    ? {
        'Access-Control-Allow-Origin': corsOriginValue,
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept'
      }
    : {}

  return axios.post(ACTIVATE_USER_URL(environment), {
    password: password,
    headers: corsHeaders
  })
}
