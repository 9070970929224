import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { DeveloperSecurityKeyType } from '@local/types'
import { ConfigurableErrorPage } from '@local/depot-error-pages'
import { UserActivationPage } from '@local/user-activation'
import { useSecurityKeyData } from '../../hooks/useSecurityKeyData'
import { ActivationEnvironmentProvider } from '../../contexts/ActivationEnvironmentContext'

/**
 * Retrieve security key data
 * [Happy Path]
 * If type is INVITATION, render the UserActivationPage
 *
 * [Error scenarios]
 * Render configurable error page to address the following:
 *  - Key not found or expired - 404
 *  - Too many requests (rate limiting) - 429
 *  - Internal server error - 500
 */
export const PageWrapper = () => {
  const env = getCurrentEnvironment()
  const location = useLocation()

  const securityKeyGuid =
    new URLSearchParams(location.search).get('securityKeyGuid') || ''

  const {
    securityKeyData,
    securityKeyDataLoading,
    securityKeyDataError,
    activationEnv
  } = useSecurityKeyData(securityKeyGuid, env)

  if (securityKeyDataLoading) {
    return (
      <div className='w-full h-full flex flex-row justify-center'>
        <MerryGoRound size='lg' testId='loading-data' />
      </div>
    )
  }

  if (securityKeyDataError) {
    const errorStatus = (securityKeyDataError as AxiosError)?.response?.status
    switch (errorStatus) {
      case 404:
        return <ConfigurableErrorPage errorType='EXPIRED_LINK' />
      case 429:
      case 500:
      default:
        return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
    }
  }

  if (!!securityKeyData) {
    switch (securityKeyData.type) {
      case DeveloperSecurityKeyType.INVITATION:
        return (
          <ActivationEnvironmentProvider activationEnv={activationEnv}>
            <UserActivationPage securityKeyData={securityKeyData} />
          </ActivationEnvironmentProvider>
        )
      case DeveloperSecurityKeyType.RESET_PASSWORD:
        // The reset password flow is handled through Auth0 directly.
        return <ConfigurableErrorPage errorType='NOT_FOUND' />
      default:
        return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
    }
  }
  return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
}
