import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { ToastLogoIcon } from '@toasttab/buffet-pui-icons'
import { TableIllustration } from '@toasttab/buffet-pui-illustrations'
import {
  Header,
  PageContainer,
  Page,
  PageBody,
  PageAside,
  PageMain,
  ContinueButton,
  NavigationContainer,
  ContentHeader,
  ContentFooter
} from '@toasttab/buffet-pui-wizard-templates'

export const ConfirmationPage: React.FunctionComponent = () => {
  const location = useLocation()
  const orgName = location?.state?.orgName
  return (
    <Page>
      {orgName && (
        <Header
          logoRelativeLink={{ link: '', label: '' }}
          icon={<ToastLogoIcon accessibility='decorative' />}
          title={orgName}
        />
      )}
      <PageContainer>
        <PageBody>
          <PageMain containerClassName='mb-10'>
            <ContentHeader
              title='All set!'
              description='Your account has been activated. Use the password you just set to login.'
            />
            <div className='flex justify-end mt-6'>
              <TableIllustration />
            </div>
            <ContentFooter>
              <NavigationContainer>
                <ContinueButton
                  as='a'
                  testId={'login-now-button'}
                  type='button'
                  href={`${window.location.origin}/login`}
                >
                  Login now
                </ContinueButton>
              </NavigationContainer>
            </ContentFooter>
          </PageMain>
          <PageAside></PageAside>
        </PageBody>
      </PageContainer>
    </Page>
  )
}
