import React from 'react'

import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading,
  ErrorText
} from '@toasttab/buffet-pui-error-pages'
import { ErrorDefinition, HANDLED_ERRORS } from './constants'

export interface ConfigurableErrorPageProps {
  errorType: keyof typeof HANDLED_ERRORS
}

export const ConfigurableErrorPage: React.FunctionComponent<
  ConfigurableErrorPageProps
> = ({ errorType }) => {
  const error: ErrorDefinition = HANDLED_ERRORS[errorType]

  return (
    <ErrorContainer>
      <div data-testid={error.id}>
        <div
          className='hidden md:block visible mx-auto'
          style={{
            maxWidth: 600
          }}
        >
          {error.imageDesktop}
        </div>
        <div
          className='block md:hidden visible mx-auto'
          style={{
            maxWidth: 250
          }}
        >
          {error.imageMobile}
        </div>
      </div>
      <div>
        <ErrorHeading>{error.heading}</ErrorHeading>
        <ErrorSubheading>{error.subheading}</ErrorSubheading>
      </div>
      {error.additionalContent && (
        <div className='space-y-2'>
          <ErrorText>{error.additionalContent}</ErrorText>
        </div>
      )}
    </ErrorContainer>
  )
}
