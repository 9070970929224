import * as React from 'react'
import { SecurityKeyData } from '@local/types'
import { UserActivationForm } from '@local/user-activation'
import { EmailDisplayIcon, ToastLogoIcon } from '@toasttab/buffet-pui-icons'
import {
  ContentHeader,
  Header,
  Page,
  PageAside,
  PageBody,
  PageContainer,
  PageInfoList,
  PageMain,
  WizardClose
} from '@toasttab/buffet-pui-wizard-templates'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'

export const USER_ACTIVATION_HELP_ITEMS = [
  {
    Icon: EmailDisplayIcon,
    header: 'How do I change my Toast email address?',
    description:
      'You can change your Toast account email in the login information section within your My Account page. Note, you will need to reverify when changing to a new email.'
  }
]

export interface UserActivationPageProps {
  securityKeyData: SecurityKeyData
}

export const UserActivationPage = ({
  securityKeyData
}: UserActivationPageProps) => {
  return (
    <Page testId={'user-activation-page'}>
      <Header
        logoRelativeLink={{ link: 'https://pos.toasttab.com/', label: 'Toast' }}
        icon={<ToastLogoIcon accessibility='decorative' />}
        title={securityKeyData.organizationName}
        wizardClose={<WizardClose className='hidden' />}
      />
      <PageContainer>
        <PageBody>
          <PageMain containerClassName='mb-10'>
            <ContentHeader
              title='Activate your Toast developer account'
              description="You'll use this account to log into the Toast Developer Portal."
              contentDivider={true}
            />
            <div className='flex flex-row justify-between justify-items-start mt-2 mb-6'>
              <div className='w-full'>
                <ReadonlyText
                  label='Email Address'
                  value={securityKeyData.userEmail}
                />
              </div>
              <div className='w-full'>
                <ReadonlyText
                  label='Name'
                  value={securityKeyData.userName}
                  className='w-full'
                />
              </div>
            </div>
            <UserActivationForm
              passwordInputLabel={'Create Password'}
              orgName={securityKeyData.organizationName || ''}
            />
          </PageMain>
          <PageAside>
            <PageInfoList items={USER_ACTIVATION_HELP_ITEMS} />
          </PageAside>
        </PageBody>
      </PageContainer>
    </Page>
  )
}
